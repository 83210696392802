
import { Vue, Options } from 'vue-class-component';
import { Domain, Organisation } from '@/models';
import { DomainService, OrganisationService } from '@/services/api';
import { BaseButton, BaseSelect, BaseCard, BaseModal, BaseIcon } from '@/lib/components';
import { IOption } from '@/lib';
import { vaUnits } from '@/constants';
import { useNotificationStore } from '@/stores/notification.store';

@Options({
  props: {
    organisation: {
      type: Object,
      required: true
    }
  },
  components: {
    BaseCard,
    BaseButton,
    BaseSelect,
    BaseModal,
    BaseIcon
  }
})
export default class OrganisationPreferencesPage extends Vue {
  organisation!: Domain | Organisation;
  loading = true;
  parentDomainConfig: Organisation['parent_domain_config'] | null = null;
  vaUnit = '';
  kUnit = '';

  errors: { [key: string]: Array<string> } = {};

  orgService = new OrganisationService();
  domainService = new DomainService();

  confirmChanges = false;

  notificationStore = useNotificationStore();

  get preferencesUpdated() {
    return (
      !this.organisation.config?.units ||
      this.vaUnit !== this.organisation.config.units.va_unit ||
      this.kUnit !== this.organisation.config.units.k_unit
    );
  }

  get kUnitsOptions(): IOption[] {
    return [
      {
        value: 'mm',
        label:
          this.defaultKUnit === 'mm'
            ? `${this.$t('platform.domain-admin.preferences.k-units.mm')} (${this.$t('platform.common.default')})`
            : (this.$t('platform.domain-admin.preferences.k-units.mm') as string)
      },
      {
        value: 'D',
        label:
          this.defaultKUnit === 'D'
            ? `${this.$t('platform.domain-admin.preferences.k-units.D')} (${this.$t('platform.common.default')})`
            : (this.$t('platform.domain-admin.preferences.k-units.D') as string)
      }
    ];
  }

  get vaUnitsOptions(): IOption[] {
    return vaUnits.map((unit) => ({
      label:
        unit === this.defaultVAUnit
          ? `${this.$t(`platform.domain-admin.preferences.va-units.${unit}`)} (${this.$t('platform.common.default')})`
          : (this.$t(`platform.domain-admin.preferences.va-units.${unit}`) as string),
      value: unit
    }));
  }

  get defaultKUnit(): string | null {
    return this.parentDomainConfig?.config?.units?.k_unit || null;
  }

  get defaultVAUnit(): string | null {
    return this.parentDomainConfig?.config?.units?.va_unit || null;
  }

  get orgType(): string {
    return this.organisation.type;
  }

  created() {
    this.$watch('organisation', async () => {
      this.loading = true;
      this.vaUnit = this.organisation.config?.units.va_unit || '';
      this.kUnit = this.organisation.config?.units.k_unit || '';
      await this.fetchParentDomainConfig();
      this.loading = false;
    }, {
      immediate: true
    });
  }

  async fetchParentDomainConfig() {
    if (this.orgType === 'organisations') {
      this.parentDomainConfig = (this.organisation as Organisation).parent_domain_config;
    } else {
      const parentId = (this.organisation as Domain).parent_id || null;
      if (parentId) {
        try {
          const domain = await this.domainService.fetch(parentId);
          this.parentDomainConfig = { config: domain.config };
        } catch (e) {
          this.notificationStore.addErrorNotification({
            title: this.$t('platform.error.fetch-data')
          });
        }
      }
    }
  }

  showModal() {
    if (this.orgType === 'organisations') {
      this.confirmChanges = true;
    } else {
      this.save();
    }
  }

  hideModal() {
    this.confirmChanges = false;
  }

  async save() {
    try {
      const updates = {
        config: {
          units: {
            k_unit: this.kUnit,
            va_unit: this.vaUnit
          }
        }
      };
      let organisation: Organisation | Domain;
      if (this.orgType === 'organisations') {
        organisation = await this.orgService.update(this.organisation.id, updates);
      } else {
        organisation = await this.domainService.update(this.organisation.id, updates);
      }
      this.$emit('update', { ...this.organisation, ...organisation });
      this.hideModal();
      this.notificationStore.addSuccessNotification({
        title: this.$t('platform.org-unit.update-success')
      });
    } catch (error) {
      if (error.response.status === 422) {
        this.errors = error.response.data.errors;
      } else {
        this.notificationStore.addErrorNotification({
          title: this.$t('platform.org-unit.update-error')
        });
      }
    }
  }
}
