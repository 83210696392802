import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col space-y-2" }
const _hoisted_2 = { class: "text-gray-900 text-lg font-semibold" }
const _hoisted_3 = {
  key: 0,
  class: "leading-tight text-gray-600"
}
const _hoisted_4 = { class: "grid grid-cols-2 gap-6" }
const _hoisted_5 = { class: "p-10 max-w-xs text-center" }
const _hoisted_6 = { class: "text-lg font-semibold leading-tight text-gray-900 mt-6 mb-2" }
const _hoisted_7 = { class: "text-gray-600" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseSelect = _resolveComponent("BaseSelect")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_BaseCard = _resolveComponent("BaseCard")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_BaseModal = _resolveComponent("BaseModal")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.loading)
      ? (_openBlock(), _createBlock(_component_BaseCard, {
          key: 0,
          class: "space-y-6"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('platform.domain-admin.preferences.units-of-measurement')), 1),
              (_ctx.orgType)
                ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t(`platform.domain-admin.${_ctx.orgType}.preferences.instructions`)), 1))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_BaseSelect, {
                modelValue: _ctx.vaUnit,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.vaUnit) = $event)),
                "data-dusk": "va-unit-select",
                label: _ctx.$t('platform.domain-admin.preferences.va'),
                options: _ctx.vaUnitsOptions
              }, null, 8, ["modelValue", "label", "options"]),
              _createVNode(_component_BaseSelect, {
                modelValue: _ctx.kUnit,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.kUnit) = $event)),
                "data-dusk": "k-unit-select",
                label: _ctx.$t('platform.domain-admin.preferences.k-readings'),
                options: _ctx.kUnitsOptions
              }, null, 8, ["modelValue", "label", "options"])
            ]),
            _createElementVNode("div", null, [
              _createVNode(_component_BaseButton, {
                size: "large",
                disabled: !_ctx.preferencesUpdated,
                onClick: _ctx.showModal
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('platform.common.save-changes')), 1)
                ]),
                _: 1
              }, 8, ["disabled", "onClick"]),
              _createVNode(_component_BaseButton, {
                size: "large",
                color: "ghost",
                class: "ml-4",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$router.back()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('platform.common.cancel')), 1)
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.confirmChanges)
      ? (_openBlock(), _createBlock(_component_BaseModal, {
          key: 1,
          "close-on-background-click": false
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_BaseIcon, {
                name: "bell",
                class: "shadow-lg bg-alert-400 text-white rounded-xl p-4",
                height: "64px"
              }),
              _createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.$t('platform.common.are-you-sure')), 1),
              _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t('platform.domain-admin.preferences.confirm-instructions')), 1),
              _createVNode(_component_BaseButton, {
                "data-dusk": "save-modal-changes",
                size: "large",
                block: "",
                color: "primary",
                class: "mt-6",
                onClick: _ctx.save
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('platform.common.save-changes')), 1)
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_BaseButton, {
                size: "large",
                block: "",
                color: "muted",
                class: "mt-3",
                onClick: _ctx.hideModal
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('platform.common.cancel')), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}